<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex justify-center" style="width: 40%">
          <img alt="logo" src="@/assets/images/sme_logo.png" />
        </div>
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4">You're almost there!</h3>
          <label class="text-center">Confirm your email address, so we know that it's really you!</label>
          <label>Confirmation email has been sent to <span class="text-blue-500 cursor-pointer">test@example.com</span></label>
          <Divider />
          <label class="text-center text-sm">Not Received? <span class="text-blue-500 cursor-pointer">resend confirmation link</span></label>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="sign-up" src="@/assets/images/signup-3.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import Divider from 'primevue/divider';
export default {
  components: {
    Divider,
  },
  data() {
    return {
      consult: true,
      options: [
        { label: 'Find a Resource', value: false },
        { label: 'Provide a Service', value: true },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.facebook {
  background-color: #3b5998;
}

.facebook:hover {
  background-color: #294175;
}

.google {
  background-color: #dd4b39;
}

.google:hover {
  background-color: #c23321;
}
</style>
